<template>
  <div>
    <b-button :id="`popover-target-${name}-${index}`" size="sm" block pill :variant="info.has_issue ? 'outline-danger' : 'outline-secondary'"
      ><i class="fa fa-info-circle"></i
    ></b-button>
    <b-popover :target="`popover-target-${name}-${index}`" triggers="hover" placement="left">
      <b-row v-if="!_.isNil(info.service_route_name)" class="order_generator_info_border_bottom thick">
        <b-col class="text-left" cols="3"><nobr>SR</nobr></b-col>
        <b-col>{{ info.service_route_name }}</b-col>
      </b-row>
      <b-row v-if="!_.isNil(info.warehouse_name)" class="order_generator_info_border_bottom thick">
        <b-col class="text-left" cols="3"><nobr>Wh</nobr></b-col>
        <b-col>{{ info.warehouse_name }}</b-col>
      </b-row>
      <b-row v-if="!_.isNil(info.fleet_names)" class="order_generator_info_border_bottom thick">
        <b-col class="text-left" cols="3"><nobr>Flt</nobr></b-col>
        <b-col>{{ info.fleet_names }}</b-col>
      </b-row>
      <b-row v-if="!_.isNil(info.tangible_name)" class="order_generator_info_border_bottom">
        <b-col class="text-left" cols="3"><nobr>Ta</nobr></b-col>
        <b-col>{{ info.tangible_name }}</b-col>
      </b-row>
      <b-row v-if="!_.isNil(info.remaining_quantity_per_day)" class="order_generator_info_border_bottom thick" :class="{ issue: info.remaining_quantity_per_day <= 0 }">
        <b-col class="text-left" cols="3"><nobr>Ta Q</nobr></b-col>
        <b-col class="text-right"
          ><nobr>{{ info.remaining_quantity_per_day }} - ({{info.consumed_quantity_per_day}} / {{info.limited_quantity_per_day}})</nobr></b-col
        >
      </b-row>
      <b-row v-if="!_.isNil(info.lorry_name)" class="order_generator_info_border_bottom">
        <b-col class="text-left" cols="3"><nobr>Lr</nobr></b-col>
        <b-col>{{ info.lorry_name }}</b-col>
      </b-row>
      <b-row v-if="!_.isNil(info.remaining_volume_per_day)" class="order_generator_info_border_bottom" :class="{ issue: info.remaining_volume_per_day < 0 }">
        <b-col class="text-left" cols="3"><nobr>Lr Vo</nobr></b-col>
        <b-col class="text-right"
          ><nobr>{{ info.remaining_volume_per_day.toFixed(3) }} - ({{info.consumed_volume_per_day.toFixed(3)}} / {{info.limited_volume_per_day.toFixed(3)}})</nobr></b-col
        >
      </b-row>
      <b-row v-if="!_.isNil(info.remaining_weight_per_day)" class="order_generator_info_border_bottom thick" :class="{ issue: info.remaining_weight_per_day < 0 }">
        <b-col class="text-left" cols="3"><nobr>Lr We</nobr></b-col>
        <b-col class="text-right"
          ><nobr>{{ info.remaining_weight_per_day.toFixed(3) }} - ({{info.consumed_weight_per_day.toFixed(3)}} / {{info.limited_weight_per_day.toFixed(3)}})</nobr></b-col
        >
      </b-row>
      <b-row v-if="!_.isNil(info.driver_name)" class="order_generator_info_border_bottom">
        <b-col class="text-left" cols="3"><nobr>Dr</nobr></b-col>
        <b-col>{{ info.driver_name }}</b-col>
      </b-row>
      <b-row v-if="!_.isNil(info.remaining_addresses_per_day)" class="order_generator_info_border_bottom thick" :class="{ issue: info.remaining_addresses_per_day < 0 }">
        <b-col class="text-left" cols="3"><nobr>Dr A</nobr></b-col>
        <b-col class="text-right"
          ><nobr>{{ info.remaining_addresses_per_day }} - ({{info.consumed_addresses_per_day}} / {{info.limited_addresses_per_day}})</nobr></b-col
        >
      </b-row>
      <b-row v-if="!_.isEmpty(info.reason)" class="order_generator_info_border_bottom thick" :class="{ issue: true }">
        <b-col class="text-left" cols="3"><nobr>R</nobr></b-col>
        <b-col class="text-right">{{ info.reason}}</b-col>
      </b-row>
      <b-row v-if="!_.isEmpty(info.limiting_reason)" class="order_generator_info_border_bottom thick" :class="{ issue: true }">
        <b-col class="text-left" cols="3"><nobr>LR</nobr></b-col>
        <b-col class="text-right">{{ info.limiting_reason}}</b-col>
      </b-row>
    </b-popover>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {},
  props: {
    name: {
      required: true,
    },
    info: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    this._ = _;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.order_generator_info_border_bottom {
  border-bottom: 1px solid #ccc;
  &.thick {
    border-bottom: 2px solid #ccc;
    margin-bottom: 3px;
    padding-bottom: 3px;
  }

  &.issue {
    color: #850000;
  }
}
</style>
